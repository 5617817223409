import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SubFooter from '../../components/SubFooter';
import ModalRegisterToken from '../../components/ModalRegisterToken';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5f5f5;
`;

const FormsToken: React.FC = () => {
  return (
    <PageWrapper>
      <Header style={{ backgroundColor: '#171717', borderBottom: '0px' }} />
      <MainContent>
        <ModalRegisterToken />
      </MainContent>
      <Footer />
      <SubFooter />
    </PageWrapper>
  );
};

export default FormsToken;

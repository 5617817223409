import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background-color: #494949;
  width: 231px;
  height: 65px;

  padding: 0;

  color: #f5f5f5;
  font-weight: 500;
  font-size: 22px;

  border: none;
  border-radius: 10px;

  transition: background 0.5s;

  &:hover {
    background-color: ${shade(0.2, '#494949')};
    transition: background 0.5s;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 231px;
    height: 65px;

    border: none;
    border-radius: 10px;
  }

  @media (max-width: 1199px) {
    width: 135px;
    height: 37px;

    font-size: 15px;

    div {
      width: 135px;
      height: 37px;
    }
  }

  @media (max-width: 1024px) {
    width: 135px;
    height: 50px;

    font-size: 15px;

    div {
      width: 135px;
      height: 50px;
    }
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Toaster, toast } from 'sonner';

import Input from '../../../Input';
import Select from '../../../Select';
import Button from '../../../ButtonSubmit';
import { Container, toastStyles as ToastStyles, FormField } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';

import SimpleDialog from '../../../DialogComponentMappForm';

interface LegalRepresentative {
  name: string;
  cpf: string;
}

type CycleType = 'semanal' | 'quinzenal' | 'mensal' | '45dias';

interface ModalRegisterData {
  razaoSocial: string;
  cidadeSede: string;
  cep: string;
  cnpj: string;
  representantesLegais: LegalRepresentative[];

  quantidadeAutonomos: string;

  endereco: string;
  nomeContato: string;
  telefoneContato: string;
  emailContato: string;

  cicloOperacional: CycleType;

  revenueShare: string;
}

const ciclosOperacionaisOptions = [
  { value: '', label: 'Selecione o ciclo operacional' },
  {
    value: 'semanal',
    label: 'Operação Semanal (7 dias)',
  },
  {
    value: 'quinzenal',
    label: 'Operação Quinzenal (15 dias)',
  },
  {
    value: 'mensal',
    label: 'Operação Mensal (30 dias)',
  },
  { value: '45dias', label: 'Operação 45 dias' },
];

const revenueShareOptions = [
  { value: '', label: 'Selecione a opção de Revenue Share' },
  { value: 'sim', label: 'Sim' },
  { value: 'nao', label: 'Não' },
];

const GOOGLE_SHEETS_URL = process.env.REACT_APP_GOOGLE_SHEETS_URL;

const Modal1: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState<ModalRegisterData | null>(null);
  const [representatives, setRepresentatives] = useState<LegalRepresentative[]>(
    [{ name: '', cpf: '' }],
  );
  const [, setInputMaskChange] = useState('');

  const addRepresentative = () => {
    if (representatives.length < 3) {
      setRepresentatives([...representatives, { name: '', cpf: '' }]);
    }
  };

  const sendToGoogleSheets = async (
    data: ModalRegisterData,
  ): Promise<boolean> => {
    if (!GOOGLE_SHEETS_URL) {
      return false;
    }

    try {
      const representantesPlanos = data.representantesLegais.reduce(
        (acc, rep, index) => ({
          ...acc,
          [`representanteLegalNome${index + 1}`]: rep.name,
          [`representanteLegalCPF${index + 1}`]: rep.cpf,
        }),
        {},
      );

      const getTaxaDesconto = (ciclo: string) => {
        switch (ciclo) {
          case 'semanal':
            return '1,89% + R$0,99';
          case 'quinzenal':
            return '2,89% + R$0,99';
          case 'mensal':
            return '4,99% + R$0,99';
          case '45dias':
            return '6,99% + R$0,99';
          default:
            return '';
        }
      };

      const formattedData = {
        razaoSocial: data.razaoSocial,
        cidadeSede: data.cidadeSede,
        cep: data.cep,
        cnpj: data.cnpj,
        ...representantesPlanos,

        quantidadeAutonomos: data.quantidadeAutonomos,

        endereco: data.endereco,
        nomeContato: data.nomeContato,
        telefoneContato: data.telefoneContato,
        emailContato: data.emailContato,

        cicloOperacional:
          ciclosOperacionaisOptions.find(
            option => option.value === data.cicloOperacional,
          )?.label || data.cicloOperacional,
        taxaDesconto: getTaxaDesconto(data.cicloOperacional),

        revenueShare:
          revenueShareOptions.find(option => option.value === data.revenueShare)
            ?.label || data.revenueShare,

        dataEnvio: new Date().toISOString(),
      };

      await fetch(GOOGLE_SHEETS_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });

      return true;
    } catch (error) {
      console.error('Erro ao enviar para o Google Sheets:', error);
      return false;
    }
  };

  const handleSubmit = useCallback(
    async (data: ModalRegisterData): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          razaoSocial: Yup.string().required('Razão Social é obrigatória'),
          cidadeSede: Yup.string().required('Cidade sede é obrigatória'),
          cep: Yup.string()
            .length(9, 'Cep deve ter 9 digitos já com formatação')
            .required('CEP é obrigatório'),
          cnpj: Yup.string()
            .length(18, 'CNPJ deve ter 18 digitos já com formatação')
            .required('CNPJ é obrigatório'),
          quantidadeAutonomos: Yup.string().required(
            'Quantidade de autônomos é obrigatória',
          ),
          endereco: Yup.string().required('Endereço é obrigatório'),
          nomeContato: Yup.string().required('Nome do contato é obrigatório'),
          telefoneContato: Yup.string()
            .length(15, 'Telefone deve ter 15 digitos já com formatação')
            .required('Telefone é obrigatório'),
          emailContato: Yup.string()
            .email('E-mail inválido')
            .required('E-mail é obrigatório'),
          cicloOperacional: Yup.string().required(
            'Ciclo operacional é obrigatório',
          ),
          revenueShare: Yup.string().required('Revenue Share é obrigatório'),
          representantesLegais: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(
                'Nome do representante é obrigatório',
              ),
              cpf: Yup.string()
                .length(14, 'Cpf deve ter 14 digitos já com formatação')
                .required('CPF do representante é obrigatório'),
            }),
          ),
        });

        await schema.validate(data, { abortEarly: false });

        setFormData(data);
        setIsDialogOpen(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha todos os campos corretamente.');
          return;
        }
        toast.error('Ocorreu um erro ao enviar o formulário.');
      }
    },
    [],
  );

  const handleDialogConfirm = async () => {
    if (!formData) return;

    setIsSubmitting(true);
    try {
      const sheetsResult = await sendToGoogleSheets(formData);

      if (sheetsResult) {
        toast.success('Cadastro realizado com sucesso! Entraremos em contato.');
        formRef.current?.reset();
        setRepresentatives([{ name: '', cpf: '' }]);
      } else {
        toast.error(
          'Houve um problema ao salvar seus dados. Por favor, tente novamente.',
        );
      }
    } catch (apiError: any) {
      let errorMessage = 'Erro ao realizar cadastro. Tente novamente.';

      if (apiError.response?.data?.message) {
        errorMessage = apiError.response.data.message;
      } else if (apiError.message) {
        errorMessage = apiError.message;
      }

      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
      setIsDialogOpen(false);
      setFormData(null);
    }
  };

  return (
    <Container>
      <ToastStyles />
      <Toaster theme="system" position="top-right" expand={false} richColors />

      <SimpleDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleDialogConfirm}
        cycleType={formData?.cicloOperacional || 'semanal'}
        hasRevShare={formData?.revenueShare === 'sim'}
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div id="form">
          <h2
            style={{
              marginBottom: '2rem',
              fontWeight: 600,
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          >
            Cadastro Plific
          </h2>

          <section>
            <h3
              style={{
                marginBottom: '1rem',
                fontWeight: 600,
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
            >
              Sobre a Empresa
            </h3>

            <FormField>
              <label htmlFor="razaoSocial">Razão Social</label>
              <Input name="razaoSocial" placeholder="Digite a razão social" />
            </FormField>

            <FormField>
              <label htmlFor="cidadeSede">Cidade Sede</label>
              <Input name="cidadeSede" placeholder="Digite a cidade sede" />
            </FormField>

            <FormField>
              <label htmlFor="cep">CEP</label>
              <Input
                name="cep"
                placeholder="Digite o CEP"
                mask="cep"
                inputMaskChange={(text: string) => setInputMaskChange(text)}
                maxLength={9}
                inputMode="numeric"
              />
            </FormField>

            <FormField>
              <label htmlFor="cnpj">CNPJ</label>
              <Input
                name="cnpj"
                placeholder="Digite o CNPJ"
                mask="cnpj"
                inputMaskChange={(text: string) => setInputMaskChange(text)}
                maxLength={18}
                inputMode="numeric"
              />
            </FormField>

            {representatives.map((_, index) => (
              <div
                key={index}
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                <FormField
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                  }}
                >
                  <label>Representante Legal {index + 1}</label>
                  <Input
                    name={`representantesLegais.${index}.name`}
                    placeholder="Nome do representante"
                  />
                  <Input
                    name={`representantesLegais.${index}.cpf`}
                    placeholder="CPF do representante"
                    mask="cpf"
                    inputMaskChange={(text: string) => setInputMaskChange(text)}
                    maxLength={14}
                    inputMode="numeric"
                  />
                </FormField>
              </div>
            ))}

            <Button
              type="button"
              onClick={addRepresentative}
              style={{
                marginTop: '.5rem',
                fontSize: '15px',
              }}
              disabled={representatives.length >= 3}
            >
              {representatives.length >= 3
                ? 'Limite máximo atingido'
                : 'Adicionar Representante'}
            </Button>
          </section>

          <section style={{ marginTop: '1rem' }}>
            <FormField style={{ marginTop: '1rem' }}>
              <label htmlFor="quantidadeAutonomos">
                Quantidade de Autônomos Cadastrados
              </label>
              <Input
                name="quantidadeAutonomos"
                placeholder="Digite a quantidade de autônomos"
                type="number"
              />
            </FormField>
          </section>

          <section style={{ marginTop: '2rem' }}>
            <h3
              style={{
                marginBottom: '1rem',
                fontWeight: 600,
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
            >
              Contato da Empresa
            </h3>
            <FormField>
              <label htmlFor="endereco">Endereço</label>
              <Input name="endereco" placeholder="Digite o endereço completo" />
            </FormField>

            <FormField>
              <label htmlFor="nomeContato">Nome do Contato</label>
              <Input
                name="nomeContato"
                placeholder="Digite o nome do contato"
              />
            </FormField>

            <FormField>
              <label htmlFor="telefoneContato">Telefone</label>
              <Input
                name="telefoneContato"
                placeholder="Digite o telefone"
                mask="phone"
                type="tel"
                inputMaskChange={(text: string) => setInputMaskChange(text)}
                maxLength={14}
                inputMode="tel"
              />
            </FormField>

            <FormField>
              <label htmlFor="emailContato">E-mail</label>
              <Input
                name="emailContato"
                placeholder="Digite o e-mail"
                inputMode="email"
              />
            </FormField>
          </section>

          <section style={{ marginTop: '2rem' }}>
            <h3
              style={{
                marginBottom: '1rem',
                fontWeight: 600,
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
            >
              Ciclos Operacionais
            </h3>
            <FormField>
              <label htmlFor="cicloOperacional">
                Selecione o Ciclo Operacional
              </label>
              <Select
                name="cicloOperacional"
                options={ciclosOperacionaisOptions}
                className="form-select"
              />
              <ul>
                <li>
                  <span
                    style={{
                      fontSize: '0.7rem',
                      color: '#4B5563',
                      fontWeight: 300,
                    }}
                  >
                    - Ciclo de{' '}
                    <strong style={{ fontWeight: 600 }}>7 dias</strong>{' '}
                    performados pelo Usuário (Autônomo), o pagamento à Plific
                    pela contratada é em{' '}
                    <strong style={{ fontWeight: 600 }}>3 dias </strong>
                    depois do final do Ciclo{' '}
                    <strong style={{ fontWeight: 600 }}>(10º dia)</strong>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontSize: '0.7rem',
                      color: '#4B5563',
                      fontWeight: 300,
                    }}
                  >
                    - Ciclo de{' '}
                    <strong style={{ fontWeight: 600 }}>15 dias</strong>{' '}
                    performados pelo Usuário (Autônomo), o pagamento à Plific
                    pela contratada é em{' '}
                    <strong style={{ fontWeight: 600 }}>3 dias </strong>
                    depois do final do Ciclo{' '}
                    <strong style={{ fontWeight: 600 }}>(18º dia)</strong>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontSize: '0.7rem',
                      color: '#4B5563',
                      fontWeight: 300,
                    }}
                  >
                    - Ciclo de{' '}
                    <strong style={{ fontWeight: 600 }}>30 dias</strong>{' '}
                    performados pelo Usuário (Autônomo), o pagamento à Plific
                    pela contratada é em{' '}
                    <strong style={{ fontWeight: 600 }}>3 dias</strong> depois
                    do final do Ciclo{' '}
                    <strong style={{ fontWeight: 600 }}>(33º dia)</strong>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontSize: '0.7rem',
                      color: '#4B5563',
                      fontWeight: 300,
                    }}
                  >
                    - Ciclo de{' '}
                    <strong style={{ fontWeight: 600 }}>45 dias</strong>{' '}
                    performados pelo Usuário (Autônomo), o pagamento à Plific
                    pela contratada é em{' '}
                    <strong style={{ fontWeight: 600 }}>3 dias</strong> depois
                    do final do Ciclo{' '}
                    <strong style={{ fontWeight: 600 }}>(48º dia)</strong>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontSize: '0.7rem',
                      color: '#4B5563',
                      fontWeight: 600,
                    }}
                  >
                    A antecipação é feita pelo Usuário (Autônomo) a partir do 2º
                    dia após o início do Ciclo para antecipar o primeiro dia
                    util performado por ele.
                  </span>
                </li>
              </ul>
            </FormField>
          </section>

          <section style={{ marginTop: '2rem' }}>
            <h3
              style={{
                marginBottom: '1rem',
                fontWeight: 600,
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
            >
              Revenue Share
            </h3>
            <p
              style={{
                marginBottom: '1rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                color: '#4B5563',
              }}
            >
              Deseja cobrar dos Usuários uma taxa de{' '}
              <strong style={{ fontWeight: 600 }}>0,5%</strong> sobre o Valor
              Solicitado de Antecipação?
            </p>
            <FormField>
              <Select
                name="revenueShare"
                options={revenueShareOptions}
                className="form-select"
              />
            </FormField>
          </section>

          <Button
            type="submit"
            disabled={isSubmitting}
            style={{
              display: 'flex',
              marginTop: '2rem',
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Modal1;

import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
`;

export const ModalTitle = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #666;
`;

export const ModalBody = styled.div`
  margin-bottom: 1.5rem;
`;

export const FeeInfo = styled.div`
  line-height: 1.6;
  color: #666;
`;

export const FeeSection = styled.div`
  margin-bottom: 1rem;

  p {
    margin: 0.5rem 0;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
`;

export const PrimaryButton = styled(Button)`
  background-color: #eec03f;
  color: black;

  &:hover {
    background-color: #f7c841;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: #e5e7eb;
  color: #374151;

  &:hover {
    background-color: #d1d5db;
  }
`;

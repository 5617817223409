/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Toaster, toast } from 'sonner';

import Input from '../../../Input';
import Button from '../../../ButtonSubmit';
import { Container, toastStyles as ToastStyles, FormField } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface IntegrationData {
  domain: string;
  accessToken: string;
  professionalCode: string;
}

const { REACT_APP_GOOGLE_SHEETS_CONFIGS_INTEGRATION_URL } = process.env;

const IntegrationForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendToGoogleSheets = async (
    data: IntegrationData,
  ): Promise<boolean> => {
    if (!REACT_APP_GOOGLE_SHEETS_CONFIGS_INTEGRATION_URL) {
      return false;
    }

    try {
      const formattedData = {
        domain: data.domain,
        accessToken: data.accessToken,
        professionalCode: data.professionalCode,

        dataEnvio: new Date().toISOString(),
      };

      await fetch(REACT_APP_GOOGLE_SHEETS_CONFIGS_INTEGRATION_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });

      return true;
    } catch (error) {
      console.error('Erro ao enviar para o Google Sheets:', error);
      return false;
    }
  };

  const handleSubmit = async (data: IntegrationData): Promise<void> => {
    try {
      setIsSubmitting(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        domain: Yup.string()
          .url('Insira uma URL válida')
          .required('O domínio é obrigatório'),
        accessToken: Yup.string().required('O token de acesso é obrigatório'),
        professionalCode: Yup.string().required(
          'O código do profissional é obrigatório',
        ),
      });

      await schema.validate(data, { abortEarly: false });

      const sheetsResult = await sendToGoogleSheets(data);

      if (sheetsResult) {
        toast.success(
          'Configuração realizado com sucesso! Entraremos em contato.',
        );
        formRef.current?.reset();
      } else {
        toast.error(
          'Houve um problema ao salvar seus dados. Por favor, tente novamente.',
        );
      }

      toast.success('Configuração salva com sucesso!');
      formRef.current?.reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast.warning('Por favor, preencha todos os campos corretamente.');
        return;
      }
      toast.error('Ocorreu um erro ao salvar as configurações.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <ToastStyles />
      <Toaster theme="system" position="top-right" expand={false} richColors />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div id="form">
          <h2
            style={{
              marginBottom: '2rem',
              fontWeight: 600,
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          >
            Configuração de Integração
          </h2>

          <FormField>
            <label htmlFor="domain">Seu domínio</label>
            <Input name="domain" placeholder="Ex: https://mototaxionline.com" />
            <span className="description">
              Digite o domínio completo da sua plataforma
            </span>
          </FormField>

          <FormField>
            <label htmlFor="accessToken">Token de acesso</label>
            <Input
              name="accessToken"
              placeholder="Digite seu token de acesso"
            />
            <span className="description">
              Gere o token de acesso através da sua plataforma
            </span>
          </FormField>

          <FormField>
            <label htmlFor="professionalCode">Código do profissional</label>
            <Input
              name="professionalCode"
              placeholder="Digite o código de um profissional"
            />
            <span className="description">
              O código do profissional é o código que seu sistema utiliza para
              identificar o profissional (Ex.:{' '}
              <strong style={{ fontWeight: 600 }}>160</strong> - João da Silva).
              Pedimos um código de um profissional teste apenas para testes de
              integração
            </span>
          </FormField>

          <Button
            type="submit"
            disabled={isSubmitting}
            style={{
              display: 'flex',
              marginTop: '2rem',
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1rem',
            }}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar configurações'}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default IntegrationForm;
